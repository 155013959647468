import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout.js'

import { MapPin, Truck } from 'react-feather'

import './RegionPage.scss'


// Export Template for use in CMS preview
export const RegionPageTemplate = ({
  title,
  subtitle,
  title1,
  title2,
  text1,
  bold2,
  text2,
  body
}) => (
  <main>
    
    <PageHeader
      title={title}
      subtitle={subtitle}
      className="delivery--header"
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <ul className="points points--delivery points--region">
          <li>
            <div className="point--Content">
                <MapPin />
              <h3 className="point--Title">{title1}</h3>
              <div className="point--Excerpt">{text1}</div>
            </div>
          </li>
          <li>
            <div className="point--Content">
                <Truck />
              <h3 className="point--Title">{title2}</h3>
              <div className="point--Excerpt">
                <strong>{bold2}</strong><br />
                {text2}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    

    <section className="section text-center">
      <div className="container">
        <h2>NOT SURE IF YOU’RE IN OUR DELIVERY AREA?</h2>
        <p>Get in touch. We can put together a tailored transportation package just for you or point you in the right direction.</p>
        <Link to="/get-in-touch/" className="Button">
            Contact us
        </Link>
      </div>
    </section>
  </main>
)

const RegionPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <RegionPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default RegionPage

export const pageQuery = graphql`
  query RegionPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        title1
        title2
        text1
        bold2
        text2
      }
    }
  }
`
